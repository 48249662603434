
import { mapState } from 'vuex'
import Loading from '@/components/common/Loading'
import ChevronLeftIcon from '@/assets/icons/library/ChevronLeftIcon'

export default {
  components: {
    Loading,
    ChevronLeftIcon,
  },
  data () {
    return {
      showBackButton: false,
    }
  },
  computed: {
    ...mapState('library', ['isLoading']),
    link () {
      return '/library'
    },
  },
  created () {
    if (this.$auth.loggedIn) {
      this.showBackButton = true
    }
  },
  methods: {
    resetFilterSearch () {
      this.$store.dispatch('library/updateShowFilterResults', false)
      this.$store.dispatch('library/updateShowSearchResults', false)
    },
  },
}

export const REGISTER_STAGE = {
  LANDING_PAGE: 0,
  FILL_FORM: 1,
  VERIFY_OTP: 2,
  OTP_VERIFIED: 3,
  SET_PASSWORD: 4,
  COMPLETED: 5,
}

const INITIAL_REGISTER_STATE = {
  stage: REGISTER_STAGE.LANDING_PAGE,
  familyName: '',
  givenName: '',
  cg: '',
  contactNumber: '',
  email: '',
  otpVerified: false,
  password: '',
  confirmPassword: '',
  dirtied: new Set(),
  countryCode: '',
}

export const state = () => ({
  loading: false,
  register: INITIAL_REGISTER_STATE,
  errorMessages: {},
  isIntegration: false,
})

export const mutations = {
  setRegisterFormState (state, { key, value }) {
    state.register[key] = value
  },
  setRegisterFormStateDirtied (state, { key }) {
    state.register.dirtied.add(key)
  },
  setErrorMessages (state, { key, messages }) {
    state.errorMessages = { ...state.errorMessages, [key]: messages }
  },
  setLoading (state, isLoading) {
    state.loading = isLoading
  },
  setIsIntegration (state, isIntegration) {
    state.isIntegration = isIntegration
  },
}

export const actions = {
  inputRegisterForm ({ commit, dispatch }, { key, value }) {
    commit('setRegisterFormState', { key, value })
    dispatch('validateRegisterForm', { key })
  },
  blurField ({ commit, dispatch }, key) {
    commit('setRegisterFormStateDirtied', { key })
    dispatch('validateRegisterForm', { key })
  },
  validateRegisterForm ({ dispatch }, { key }) {
    if (key === 'email') dispatch('validateEmail')
    if (key === 'contactNumber') dispatch('validateContactNumber')
    if (key === 'cg') dispatch('validateCg')
    const fields = ['familyName', 'givenName']
    if (fields.includes(key)) dispatch('validateRequiredFields', { key })
  },
  validateContactNumber ({ commit, state }, isNumberValid) {
    if (!state.register.dirtied.has('contactNumber')) return
    if (!state.register.contactNumber) {
      commit('setErrorMessages', {
        key: 'contactNumber',
        messages: ['Required Field'],
      })
      return
    }
    const messages = isNumberValid ? null : ['Invalid Mobile Number']
    if (isNumberValid === undefined) return
    commit('setErrorMessages', { key: 'contactNumber', messages })
  },
  validateCg ({ commit, state, rootState }) {
    if (!state.register.dirtied.has('cg')) return
    if (!state.register.cg) {
      commit('setErrorMessages', {
        key: 'cg',
        messages: ['Required Field'],
      })
      return
    }
    const isInvalidCG =
      !rootState.lookups.cgMapping[state.register.cg.toUpperCase()]
    commit('setErrorMessages', {
      key: 'cg',
      messages: isInvalidCG ? ['Invalid CG'] : null,
    })
  },
  validateRequiredFields ({ commit, state }, { key }) {
    if (!state.register.dirtied.has(key)) return
    if (!state.register[key]) {
      commit('setErrorMessages', { key, messages: ['Required Field'] })
    } else {
      commit('setErrorMessages', { key, messages: null })
    }
  },
  validateEmail ({ commit, state }) {
    if (!state.register.dirtied.has('email')) return
    if (!state.register.email) {
      commit('setErrorMessages', {
        key: 'email',
        messages: ['Required Field'],
      })
      return
    }
    const emailValidator =
      '^\\w+([\\.-]?\\w+)+@\\w+([\\.:]?\\w+)+(\\.[a-zA-Z0-9]{2,3})+$'
    const messages = state.register.email.match(emailValidator)
      ? null
      : ['Invalid Email']
    commit('setErrorMessages', { key: 'email', messages })
  },
  async sendOtp ({ commit, state, dispatch }) {
    commit('setLoading', true)
    try {
      const { data } = await this.$axios.post('/profile/send-otp', {
        countryCode: state.register.countryCode,
        contactNumber: state.register.contactNumber,
      })
      if (data.status !== 200) throw new Error('Failed in sending otp')
    } catch (e) {
      if (e.response.data && e.response.data.error && e.response.data.error.details && e.response.data.error.details.length) {
        dispatch('toast/error', e.response.data.error.details[0].message, { root: true })
      } else if (e.response.data && e.response.data.error) {
        dispatch('toast/error', e.response.data.error, { root: true })
      }
    } finally {
      commit('setLoading', false)
    }
  },
  async letsGo ({ commit, state, dispatch }) {
    commit('setLoading', true)
    try {
      await this.$axios.post('/profile/lets-go', {
        contactNumber: state.register.contactNumber,
        countryCode: state.register.countryCode,
        email: state.register.email,
      })

      commit('setRegisterFormState', { key: 'stage', value: REGISTER_STAGE.VERIFY_OTP })
    } catch (e) {
      if (e.response.data && e.response.data.message) dispatch('toast/error', e.response.data.message, { root: true })
      else dispatch('toast/error', 'error in registration step', { root: true })
    } finally {
      commit('setLoading', false)
    }
  },
  async verifyOtp ({ commit, state, dispatch }, { otp }) {
    commit('setLoading', true)
    try {
      await this.$axios.post('/profile/verify-otp', { contactNumber: state.register.contactNumber, countryCode: state.register.countryCode, otp })
      commit('setRegisterFormState', { key: 'stage', value: REGISTER_STAGE.OTP_VERIFIED })
    } catch (e) {
      if (e.response.data && e.response.data.message) dispatch('toast/error', e.response.data.message, { root: true })
      else dispatch('toast/error', 'otp verification failure', { root: true })
    } finally {
      commit('setLoading', false)
    }
  },
  async completeRegistration ({ commit, state, dispatch, rootState }) {
    commit('setLoading', true)

    try {
      const { familyName, givenName, contactNumber, password, email, countryCode } =
        state.register
      const cgId = rootState.lookups.cgMapping[state.register.cg.toUpperCase()]
      await this.$axios.post('/profile/register', {
        familyName,
        givenName,
        cg: cgId,
        contactNumber,
        password,
        email,
        countryCode,
        isIntegration: state.isIntegration,
      })
      commit('setRegisterFormState', {
        key: 'stage',
        value: REGISTER_STAGE.COMPLETED,
      })
    } catch (e) {
      let msg = e.message
      if (e.response && e.response.data && e.response.data.message) {
        msg = e.response.data.message.length && Array.isArray(e.response.data.message) ? e.response.data.message[0] : e.response.data.message
      }
      dispatch('toast/error', msg, { root: true })
    } finally {
      commit('setLoading', false)
    }
  },
  setIsIntegration ({ commit }) {
    commit('setIsIntegration', true)
  },
}

export const getters = {
  register (state) {
    return state.register
  },
}


import Loading from '@/components/common/Loading'
import PasswordEntry from '@/components/ProtectedPage/PasswordEntry'

export default {
  components: {
    Loading,
    PasswordEntry,
  },
  layout: 'empty',
  data () {
    return {
      isLoadingPassword: false,
      isLoadingWhitelist: true,
      resource: undefined,
      resStatus: 0,
      buttonText: 'Access Letters',
      slug: '',
    }
  },
  async fetch () {
    try {
      if (this.$route.name.includes('/')) {
        this.slug = this.$route.name.replace('/', '-')
        // just check if whitelisted, no data needed
        await this.$axios.get(`/cms/protected/${this.slug}/whitelist`)
      }
      // check whitelisted for main resource
      const response = await this.$axios.get('cms/protected/hogcfd/whitelist')
      this.resource = response.data
    } catch (e) {
      if (e.response && (e.response.status === 403 || e.response.status === 500)) {
        this.$router.push({ path: '/' })
      }
    } finally {
      this.$store.dispatch('protected/updateCurrResource', this.resource)
      this.isLoadingWhitelist = false
    }
  },
  methods: {
    async getResourceWithPassword (password) {
      if (this.isLoadingPassword) return
      this.isLoadingPassword = true
      try {
        if (this.$route.name.includes('/')) {
          this.slug = this.$route.name.replace('/', '-')
          // just check if password is correct, no data needed
          await this.$axios.post('/cms/protected', { password, slug: this.slug })
        }
        // check password works for main resource
        const response = await this.$axios.post('/cms/protected', { password, slug: 'hogcfd' })
        this.resource = response.data
        this.resStatus = response.status
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.resStatus = e.response.status
        }
      } finally {
        this.$store.dispatch('protected/updateCurrResource', this.resource)
        this.isLoadingPassword = false
      }
    },
  },
}


import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('board', ['boards', 'currBoard', 'myZoneFilterValue']),
  },
  methods: {
    updateMyZoneFilterValue (value) {
      this.$store.dispatch('board/updateMyZoneFilterValue', value)
      this.$store.dispatch('board/updateReadFilterValue', 'all')
      this.$store.dispatch('board/updateZoneFilterValue', [])
      this.$store.dispatch('board/updateTagFilterValue', [])
    },
  },
}

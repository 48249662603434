export const WEEKEND_HIGHLIGHTS_ENTITY = 'weekend-highlights'
export const UPCOMING_EVENTS_ENTITY = 'upcoming-events'
export const WEEKLY_EVENTS_ENTITY = 'weekly-events'
export const DOWNLOADS_ENTITY = 'downloads'
export const PERSONAL_GROWTHS_ENTITY = 'personal-growths'
export const HIGHLIGHTS_ENTITY = 'highlights'
export const IDENTITY_RESOURCES_ENTITY = 'identity-resources'
export const FORMS_ENTITY = 'forms'
export const JUST_FOR_YOUS_ENTITY = 'just-for-yous'
export const CALENDAR_EVENTS_ENTITY = 'calendar/events'
export const CALENDAR_EVENT_FILTERS_ENTITY = 'calendar/filters'
export const CALENDAR_LAST_UPDATED_DATE_ENTITY = 'calendar/last-updated-date'
export const CALENDAR_DOWNLOAD_LINK_ENTITY = 'calendar/download-link'
export const E_INVITES_ENTITY = 'resources/e-invites'
export const CALENDAR_NOTIFICATIONS_ENTITY = 'calendar/notifications'

export const cmsEntities = [
  WEEKEND_HIGHLIGHTS_ENTITY, UPCOMING_EVENTS_ENTITY, WEEKLY_EVENTS_ENTITY, DOWNLOADS_ENTITY, PERSONAL_GROWTHS_ENTITY, HIGHLIGHTS_ENTITY, IDENTITY_RESOURCES_ENTITY, FORMS_ENTITY, JUST_FOR_YOUS_ENTITY, CALENDAR_EVENTS_ENTITY, CALENDAR_EVENT_FILTERS_ENTITY, CALENDAR_LAST_UPDATED_DATE_ENTITY, CALENDAR_DOWNLOAD_LINK_ENTITY, E_INVITES_ENTITY, CALENDAR_NOTIFICATIONS_ENTITY,
]

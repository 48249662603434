import { gsap } from '../libraries/gsap'
import { ScrollTrigger } from '../libraries/gsap/ScrollTrigger'

if (process.client) {
  gsap.registerPlugin(ScrollTrigger)
}

export default ({ app }, inject) => {
  inject('gsap', gsap)
}

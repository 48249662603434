export const WEEKEND_HIGHLIGHTS = 'weekendHighlights'
export const UPCOMING_EVENTS = 'upcomingEvents'
export const WEEKLY_EVENTS = 'weeklyEvents'
export const DOWNLOADS = 'downloads'
export const PERSONAL_GROWTHS = 'personalGrowths'
export const HIGHLIGHTS = 'highlights'
export const IDENTITY_RESOURCES = 'identityResources'
export const FORMS = 'forms'
export const JUST_FOR_YOUS = 'justForYous'
export const CALENDAR_EVENTS = 'calendarEvents'
export const CALENDAR_EVENT_FILTERS = 'calendarEventFilters'
export const CALENDAR_LAST_UPDATED_DATE = 'calendarLastUpdatedDate'
export const CALENDAR_DOWNLOAD_LINK = 'calendarDownloadLink'
export const E_INVITES = 'eInvites'
export const CALENDAR_NOTIFICATIONS = 'calendarNotifications'


import markerSDK from '@marker.io/browser'

export default {
  async created () {
    await Promise.all([
      this.$store.dispatch('profile/fetchProfile'),
      this.$store.dispatch('profile/fetchPastoralAbbrevMapping'),
      this.$store.dispatch('profile/fetchMinistryStatus'),
      this.$store.dispatch('lookups/fetchIfNotYet'),
      this.$store.dispatch('lookups/fetchCgMapping'),
      this.$store.dispatch('lookups/fetchZoneCgMapping'),
      this.$store.dispatch('cms/fetchCmsContent', { entityName: 'forms', stateName: 'forms' }),
    ])
    if (process.env.NODE_ENV === 'staging') {
      const widget = await markerSDK.loadWidget({
        destination: process.env.MARKERIO_DESTINATION, // <- Your unique destination ID
      })
      widget.setReporter({ email: this.email, fullName: this.name })
    }
  },
}


import { mapGetters, mapState } from 'vuex'
import { BaseButton } from '@team-hogc/design-internal-components-vue'
import isIos from '../../mixins/isIos'
import ChevronLeftBlack from '@/components/icons/ChevronLeftBlack'
import Cancel from '@/components/icons/Cancel'
export default {
  components: {
    BaseButton,
  },
  mixins: [isIos],
  computed: {
    ...mapState('photos', ['isSelectingPhotos', 'selectedPhotos', 'isShowQr']),
    ...mapGetters('photos', ['isPhotosEmpty']),
    icon () {
      return this.isSelectingPhotos ? Cancel : ChevronLeftBlack
    },
  },
  methods: {
    onNavClick () {
      if (this.isShowQr) this.toggleQr()
      else if (this.isSelectingPhotos) this.toggleSelect()
      else this.navigateToHome()
    },
    toggleSelect () {
      this.$store.dispatch('photos/updateIsSelectingPhotos', !this.isSelectingPhotos)
      this.$store.dispatch('photos/resetSelectedPhotos')
    },
    toggleQr () {
      this.$store.dispatch('photos/updateIsShowQr', !this.isShowQr)
    },
    openScanner () {
      this.$router.push('/scanner')
    },
    navigateToHome () {
      this.$router.push('/')
    },
    download () {
      // TODO: refactor the axios
      if (!this.selectedPhotos.length) return
      const newAxios = this.$axios.create({})
      delete newAxios.defaults.headers.common.Authorization

      const calls = this.selectedPhotos.map(
        async (image) =>
          await newAxios.get(image, {
            headers: {
              'Access-Control-Allow-Origin': '*',
            },
            responseType: 'blob',
          }),
      )

      Promise.all(calls)
        .then((responses) => {
          const blobs = responses.map((res) => URL.createObjectURL(res.data))
          const date = new Date().toLocaleDateString('en-US')

          blobs.forEach((blob, index) => {
            const anchor = document.createElement('a')

            anchor.href = blob
            anchor.download = `${date}_${index}.jpg`

            anchor.click()

            URL.revokeObjectURL(blob)
          })

          this.$store.dispatch('photos/updateIsSelectingPhotos', false)
          this.$store.dispatch('photos/resetSelectedPhotos')
        })
        .catch((e) => {
          this.$store.dispatch('toast/error', e.response.data.message, { root: true })
        })
    },
  },
}

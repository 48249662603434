
import { mapState } from 'vuex'
import Loading from '@/components/common/Loading'
import ChevronLeftIcon from '@/assets/icons/library/ChevronLeftIcon'

export default {
  components: {
    Loading,
    ChevronLeftIcon,
  },
  computed: {
    ...mapState('library', ['isLoading']),
    link () {
      const pathArray = this.$route.path.split('/')
      pathArray.pop()
      const path = pathArray.join('/') || '/'
      return path
    },
  },
  methods: {
    resetFilterSearch () {
      this.$store.dispatch('library/updateShowFilterResults', false)
      this.$store.dispatch('library/updateShowSearchResults', false)
    },
  },
}

export const state = () => ({
  agmEvent: null,
  isAttendingAgm: null,
  isEventActive: null,
})

export const mutations = {
  setAgmEvent (state, agmEvent) {
    state.agmEvent = agmEvent
  },
  setIsAttendingAgm (state, isAttendingAgm) {
    state.isAttendingAgm = isAttendingAgm
  },
  setIsEventActive (state, isEventActive) {
    state.isEventActive = isEventActive
  },
}

export const actions = {
  updateAgmEvent ({ commit }, agmEvent) {
    commit('setAgmEvent', agmEvent)
  },
  updateIsAttendingAgm ({ commit }, isAttendingAgm) {
    commit('setIsAttendingAgm', isAttendingAgm)
  },
  updateIsEventActive ({ commit }, isEventActive) {
    commit('setIsEventActive', isEventActive)
  },
}

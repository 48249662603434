
import { mapState } from 'vuex'
import FilterIcon from '@/assets/icons/board/FilterIcon'
export default {
  components: {
    FilterIcon,
  },
  computed: {
    ...mapState('board', ['zoneFilters', 'tagFilters', 'readFilterValue']),
    selectedFilters () {
      return this.zoneFilters.length > 0 || this.tagFilters.length > 0 || this.readFilterValue !== 'all'
    },
  },
  methods: {
    openFilterModal () {
      this.$store.dispatch('board/updateShowFilterModal', true)
    },
  },
}

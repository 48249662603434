
import { mapState } from 'vuex'
import BoardSelect from '~/components/Board/Input/BoardSelect'

export default {
  components: {
    BoardSelect,
  },
  props: {
    showBoardChange: {
      type: Boolean,
      default: false,
    },
    isPageUniqueToBoard: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('board', ['boards', 'currBoard']),
  },
  methods: {
    openSideBar () {
      this.$store.dispatch('board/updateShowSideBar', true)
    },
    redirectMainBoard () {
      this.$store.dispatch('board/updateReadFilterValue', 'all')
      this.$store.dispatch('board/updateMyZoneFilterValue', 'all')
      this.$router.push('/birthdayboard')
    },
  },
}

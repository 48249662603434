import qs from 'qs'
import { entityToCategoryMap } from '../constants/cms/entityToCategoryMap'
import { FORMS_CATEGORY, HAPPENINGS_CATEGORY, HOME_CATEGORY, RESOURCES_CATEGORY, JUST_FOR_YOU_CATEGORY, CALENDAR_EVENTS_CATEGORY, CALENDAR_EVENT_FILTERS_CATEGORY, CALENDAR_LAST_UPDATED_DATE_CATEGORY, CALENDAR_DOWNLOAD_LINK_CATEGORY, E_INVITES_CATEGORY, CALENDAR_NOTIFICATIONS_CATEGORY } from '../constants/cms/categories'
import { DOWNLOADS, FORMS, HIGHLIGHTS, IDENTITY_RESOURCES, PERSONAL_GROWTHS, UPCOMING_EVENTS, WEEKEND_HIGHLIGHTS, WEEKLY_EVENTS, JUST_FOR_YOUS, CALENDAR_EVENTS, CALENDAR_EVENT_FILTERS, CALENDAR_LAST_UPDATED_DATE, CALENDAR_DOWNLOAD_LINK, E_INVITES, CALENDAR_NOTIFICATIONS } from '../constants/cms/state_key'

const INITIAL_STATE = {
  loading: false,
  [FORMS]: {},
  [WEEKEND_HIGHLIGHTS]: [],
  [DOWNLOADS]: [],
  [HIGHLIGHTS]: [],
  [IDENTITY_RESOURCES]: [],
  [PERSONAL_GROWTHS]: [],
  [UPCOMING_EVENTS]: [],
  [WEEKLY_EVENTS]: [],
  [E_INVITES]: [],
  [JUST_FOR_YOUS]: [],
  [CALENDAR_EVENTS]: {},
  [CALENDAR_EVENT_FILTERS]: [],
  [CALENDAR_LAST_UPDATED_DATE]: '',
  [CALENDAR_DOWNLOAD_LINK]: '',
  [CALENDAR_NOTIFICATIONS]: {},
}

export const state = () => INITIAL_STATE

export const mutations = {
  setStoreKeyAndValue (state, { key, value }) {
    state[key] = value
  },
  setLoading (state, isLoading) {
    state.loading = isLoading
  },
}

export const actions = {
  async fetchCmsContent ({ state, commit }, { entityName, stateName }) {
    // Do not refetch if already got content for particular state
    if (Object.keys(state[stateName]).length > 0) return

    commit('setLoading', true)
    const { data } = await this.$axios.get(`/cms/${entityName}`)
    const value = getTransformerFunction(entityName)(data)
    commit('setStoreKeyAndValue', { key: stateName, value })
    commit('setLoading', false)
  },
  updateLoading ({ commit }, isLoading) {
    commit('setLoading', isLoading)
  },
}

/// Transform Functions

const transformHomeItems = (items) => items.map(i => ({
  link: isProtected(i) ? getLinkForProtected(i) : getLinkIfExternal(i),
  alt: i.title,
  imageSrc: i.resource.image.url,
  dataEventTracking: i.resource.dataEventTracking,
  isExternal: i.isExternal,
}))

const transformHappeningsItems = (items) => items.map(i => ({
  title: i.title,
  body: i.body,
  buttonText: i.buttonText,
  to: isProtected(i) ? getLinkForProtected(i) : getLinkIfExternal(i),
  alt: i.title,
  imageSrc: i.resource.image.url,
  dataEventTracking: i.resource.dataEventTracking,
  cornerText: i.cornerText,
  isExternal: isProtected(i) || i.isExternal,
}))

const transformResourcesItems = (items) => items.map(i => ({
  imageSrc: i.resource.image.url,
  iconSrc: i.icon?.image?.url,
  link: isProtected(i) ? getLinkForProtected(i) : getLinkIfExternal(i),
  alt: i.title,
  dataEventTracking: i.resource.dataEventTracking,
}))

const transformJustForYouItems = (items) => items.map(i => ({
  link: getLinkIfExternal(i),
  label: i.label,
  icon: i.resource.image.url,
  isExternal: i.isExternal,
  dataEventTracking: i.resource.dataEventTracking,
}))

const transformCalendarEvents = (events) => {
  // create a hashmap of events, where the key is the month and year of the event
  const result = {}
  events.forEach((event) => {
    event = {
      title: event.title,
      description: event.description,
      eventTime: event.eventTime,
      eventLocation: event.eventLocation,
      buttonText: event.buttonText,
      isExternal: event.isExternal,
      link: getLinkIfExternal(event),
      dataEventTracking: event.dataEventTracking,
      startDate: event.startDate,
      endDate: event.endDate,
      tags: event.calendar_event_tags.sort((a, b) => a.priority - b.priority),
      imageSrc: event.image?.url,
      isCalledOut: event.isCalledOut,
      isLeadersOnly: event.isLeadersOnly,
    }
    const startDate = new Date(event.startDate)
    const startMonth = startDate.getMonth() + 1
    const startYear = startDate.getFullYear()
    const key = `${startYear}-${startMonth}`
    if (result[key]) {
      result[key].push(event)
    } else {
      result[key] = [event]
    }
  })
  return result
}

const transformCalendarEventFilters = (filters) => {
  return filters
}

const formKeyToState = {
  full_name: 'fullname',
  cg: 'cg',
  email: 'email',
  partial_nric: 'uin',
  date_of_birth: 'dob',
  contact_number: 'localMobileNumber',
  personal_id: 'personalId',
  hogc_one_id: 'id',
  gender: 'gender',
  login: 'login',
  pastoral_status: 'pastoralStatus',
  ministry_status: 'ministryStatus',
  force: 'force',
}

const transformForms = (forms) => {
  const formsDict = {}

  for (const form of forms) {
    const queryStringGenerator = (profile) => {
      const qsObj = {}
      for (const key in form.prepopulation) if (form.prepopulation[key]) qsObj[key] = profile[formKeyToState[key]]
      // change pastoral_status key to role
      if (qsObj.pastoral_status) {
        qsObj.role = qsObj.pastoral_status
        delete qsObj.pastoral_status
      }
      if (qsObj.contact_number) {
        qsObj.contact_number = qsObj.contact_number.replace('+', '')
      }
      return qs.stringify(qsObj)
    }

    formsDict[form.title] = { getSrc: (profile) => `${form.link}?${queryStringGenerator(profile)}` }
  }
  return formsDict
}

const transformCalendarLastUpdatedDate = (date) => {
  return new Date(date.date).toLocaleDateString('en-GB', {
    day: 'numeric', month: 'short', year: 'numeric',
  })
}

const getTransformerFunction = (entityName) => {
  if (entityToCategoryMap[entityName] === HOME_CATEGORY) return transformHomeItems
  if (entityToCategoryMap[entityName] === HAPPENINGS_CATEGORY) return transformHappeningsItems
  if (entityToCategoryMap[entityName] === RESOURCES_CATEGORY) return transformResourcesItems
  if (entityToCategoryMap[entityName] === FORMS_CATEGORY) return transformForms
  if (entityToCategoryMap[entityName] === E_INVITES_CATEGORY) return (result) => result
  if (entityToCategoryMap[entityName] === JUST_FOR_YOU_CATEGORY) return transformJustForYouItems
  if (entityToCategoryMap[entityName] === CALENDAR_EVENTS_CATEGORY) return transformCalendarEvents
  if (entityToCategoryMap[entityName] === CALENDAR_EVENT_FILTERS_CATEGORY) return transformCalendarEventFilters
  if (entityToCategoryMap[entityName] === CALENDAR_LAST_UPDATED_DATE_CATEGORY) return transformCalendarLastUpdatedDate
  if (entityToCategoryMap[entityName] === CALENDAR_DOWNLOAD_LINK_CATEGORY) return (link) => link.link
  if (entityToCategoryMap[entityName] === CALENDAR_NOTIFICATIONS_CATEGORY) return (result) => result
}

// Utility related to cms
const getLinkIfExternal = (item) => {
  if (!item.isExternal && item.form && item.form.title && item.form.link) return `/forms/${item.form.title}`
  if (item.isExternal && item.link && !item.link.includes('://')) return 'https://' + item.link
  return item.link
}

const getLinkForProtected = (item) => {
  return `/protected/${item.protected.slug}`
}

const isProtected = (item) => item.accessControl.hasPassword && item.protected


export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    resStatus: {
      type: Number,
      required: true,
      default: 0,
    },
    buttonText: {
      type: String,
      required: true,
      default: 'Access Resource',
    },
  },
  data () {
    return {
      password: '',
    }
  },
  computed: {
    isButtonDisabled () {
      return this.isLoading || this.password === ''
    },
    isInvalid () {
      return this.resStatus === 401
    },
  },
  methods: {
    enterPassword () {
      this.$emit('enter-password', this.password)
    },
  },
}

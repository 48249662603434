
import { mapState } from 'vuex'
import BoardToast from '@/components/Board/Main/BoardToast'
import FilterButton from '@/components/Board/Filter/FilterButton'
import FilterToggle from '@/components/Board/Filter/FilterToggle'

export default {
  components: {
    BoardToast,
    FilterButton,
    FilterToggle,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState('board', ['isBoardToastOpen', 'myZoneFilterValue']),
    isMainPage () {
      return this.$route.path === '/birthdayboard'
    },
    isMyMessagesPage () {
      return this.$route.path === '/birthdayboard/my-messages'
    },
    pageTitle () {
      if (this.$route.path === '/birthdayboard' && this.myZoneFilterValue === 'myzone') {
        return 'My Zone Messages'
      } else if (this.$route.path === '/birthdayboard') {
        return 'Church Messages'
      } else if (this.$route.path === '/birthdayboard/my-messages') {
        return 'My Messages'
      } else if (this.$route.path === '/birthdayboard/favourite-messages') {
        return 'Favourite Messages'
      } else if (this.$route.path === '/birthdayboard/pinned-messages') {
        return 'Pinned Messages'
      } else if (this.$route.path === '/birthdayboard/hidden-messages') {
        return 'Hidden Messages'
      } else {
        return ''
      }
    },
  },
  methods: {
    redirectMainBoard () {
      this.$router.push('/birthdayboard')
    },
  },
}

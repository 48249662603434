import Fuse from 'fuse.js'

export const state = () => ({
  isLoading: false,
  featuredSermons: [],
  allResources: [],
  filteredResources: [],
  filteredResourcesByTopics: {},
  filteredTopics: [],
  filteredPastors: [],
  filteredYears: [],
  categories: [],
  topics: [],
  pastors: [],
  years: [], // array of hash { year: 2020, isActive: false}
  searchSuggestions: [],
  viewedSermons: {},
  searchClient: null,
  showFilterModal: false,
  showFilterResults: false,
  showFilterResultsByTopics: false,
  showSearchPage: false,
  showSearchResults: false,
})

export const mutations = {
  setIsLoading (state, isLoading) {
    state.isLoading = isLoading
  },
  setAllResources (state, allResources) {
    state.allResources = allResources
  },
  setFeaturedSermons (state, featuredSermons) {
    state.featuredSermons = featuredSermons
  },
  setCategories (state, categories) {
    state.categories = categories
  },
  setTopics (state, topics) {
    state.topics = topics
  },
  setPastors (state, pastors) {
    state.pastors = pastors
  },
  setYears (state, years) {
    state.years = years
  },
  updateViewedSermons (state, { slug, sermon }) {
    state.viewedSermons[slug] = sermon
  },
  setSearchSuggestions (state, searchSuggestions) {
    state.searchSuggestions = searchSuggestions
  },
  setSearchClient (state, resources) {
    const combinedResourcesArray = []
    resources.forEach((item) => {
      combinedResourcesArray.push(item)
    })

    state.searchClient = new Fuse(combinedResourcesArray, {
      keys: ['title', 'description', 'pastors', 'topics'],
      includeScore: true,
      threshold: 0.1,
      ignoreLocation: true,
    })
  },
  setShowFilterModal (state, showFilterModal) {
    state.showFilterModal = showFilterModal
  },
  setFilteredValues (state, { filteredTopics, filteredPastors, filteredYears }) {
    state.filteredTopics = filteredTopics
    state.filteredPastors = filteredPastors
    state.filteredYears = filteredYears
  },
  setShowFilterResults (state, showFilterResults) {
    state.showFilterResults = showFilterResults
  },
  setShowSearchPage (state, showSearchPage) {
    state.showSearchPage = showSearchPage
    state.showFilterResults = !showSearchPage
    this.$router.push('library/results')
  },
  setShowSearchResults (state, showSearchResults) {
    state.showSearchResults = showSearchResults
  },
  setFilteredResources (state) {
    const filteredResources = state.allResources.filter((resource) => {
      const isTopicsMatch = state.filteredTopics.length > 0 ? state.filteredTopics.some(topic => resource.topics.includes(topic)) : true
      const isPastorsMatch = state.filteredPastors.length > 0 ? state.filteredPastors.some(pastor => resource.pastors.includes(pastor)) : true
      const isYearsMatch = resource.date && state.filteredYears.length > 0 ? state.filteredYears.includes(new Date(resource.date).getFullYear()) : true
      return isTopicsMatch && isPastorsMatch && isYearsMatch
    })
    state.filteredResources = filteredResources
    state.showFilterResultsByTopics = false
    state.filteredResourcesByTopics = {}
    if (state.filteredTopics.length > 0) {
      const filteredResourcesByTopics = {}
      state.filteredTopics.forEach((topic) => {
        filteredResourcesByTopics[topic] = []
      })
      state.filteredResources.forEach((resource) => {
        resource.topics.forEach((topic) => {
          filteredResourcesByTopics[topic].push(resource)
        })
      })
      // sort sermons by date
      // Object.keys(filteredResourcesByTopics).forEach((topic) => {
      //   filteredResourcesByTopics[topic].sort((a, b) => {
      //     return new Date(b.date) - new Date(a.date)
      //   })
      // })
      state.filteredResourcesByTopics = filteredResourcesByTopics
      state.showFilterResultsByTopics = true
    }
  },
}

export const actions = {
  async fetchLandingPageResources ({ state, commit }) {
    if (state.featuredSermons.length && state.categories.length && state.topics.length && state.pastors.length) return
    try {
      commit('setIsLoading', true)
      const response = await this.$axios.$get('/library/landing-page-resources')
      commit('setFeaturedSermons', response.featuredSermons)
      commit('setCategories', response.categories)
      commit('setTopics', response.topics)
      commit('setPastors', response.pastors)
      commit('setYears', response.years)
      commit('setAllResources', response.resources)
      commit('setIsLoading', false)
      commit('setSearchSuggestions', response.searchSuggestions)
      commit('setSearchClient', response.resources)
    } catch (error) {
      commit('setIsLoading', false)
    }
  },

  async fetchSermonBySlug ({ state, commit }, { slug }) {
    if (state.viewedSermons[slug]) return

    try {
      commit('setIsLoading', true)
      const sermon = await this.$axios.$get(`/library/sermons/${slug}`)
      commit('updateViewedSermons', { slug, sermon })
      commit('setIsLoading', false)
    } catch (error) {
      commit('setIsLoading', false)
      if (error.response.status === 403) {
        this.$router.push({ path: '/library/forbidden' })
      }
    }
  },

  async fetchPublicSermonBySlug ({ state, commit }, { slug }) {
    if (state.viewedSermons[slug]) return

    try {
      commit('setIsLoading', true)
      const sermon = await this.$axios.$get(`/library/pub/${slug}`)
      commit('updateViewedSermons', { slug, sermon })
      commit('setIsLoading', false)
    } catch (error) {
      commit('setIsLoading', false)
    }
  },

  updateShowFilterModal ({ commit }, showFilterModal) {
    commit('setShowFilterModal', showFilterModal)
  },

  updateFilteredValues ({ commit }, { filteredTopics, filteredPastors, filteredYears }) {
    commit('setFilteredValues', { filteredTopics, filteredPastors, filteredYears })
    commit('setFilteredResources')
  },

  updateShowFilterResults ({ commit }, showFilterResults) {
    commit('setShowFilterResults', showFilterResults)
  },

  updateShowSearchPage ({ commit }, showSearchModal) {
    commit('setShowSearchPage', showSearchModal)
  },

  updateShowSearchResults ({ commit }, showSearchResults) {
    commit('setShowSearchResults', showSearchResults)
  },
}

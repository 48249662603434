
import { mapState } from 'vuex'
import { StandardModal, BaseButton } from '@team-hogc/design-internal-components-vue'
import { REGISTER_STAGE } from '../../store/form'

export default {
  components: { StandardModal, BaseButton },
  computed: {
    registerStage () {
      return REGISTER_STAGE
    },
    ...mapState('form', ['register']),
  },
  methods: {
    inputRegisterForm (key, value) {
      this.$store.dispatch('form/inputRegisterForm', { key, value })
    },
  },
}

import { datadogLogs } from '@datadog/browser-logs'

datadogLogs.init({
  clientToken: process.env.DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: process.env.DATADOG_SERVICE_NAME,
  version: '1.0.0',
  env: process.env.DATADOG_ENV_NAME,
  forwardErrorsToLogs: true,
  sampleRate: 100,
})

import Vue from 'vue'
export const state = () => ({
  achievedMilestones: [],
  loading: false,
  error: null,
})

export const mutations = {
  setAchievedMilestones (state, achievedMilestones) {
    if (!state.achievedMilestones.includes(achievedMilestones)) {
      state.achievedMilestones.push(achievedMilestones)
      Vue.set(state, 'achievedMilestones', state.achievedMilestones)
    }
  },
  setAchievedMilestonesFromBE (state, achievedMilestonesFromBE) {
    Vue.set(state, 'achievedMilestones', achievedMilestonesFromBE)
  },
  setLoading (state, isLoading) {
    state.loading = isLoading
  },
  setError (state, error) {
    state.error = error
  },
}

export const actions = {
  async fetchMilestones ({ commit }) {
    commit('setLoading', true)
    commit('setError', null)
    try {
      const response = await this.$axios.$get('/milestones')
      commit('setAchievedMilestonesFromBE', response)
    } catch (e) {
      commit('setError', 'Unable to fetch milestones')
    } finally {
      commit('setLoading', false)
    }
  },
  async registerMilestone ({ commit }, idNumber) {
    try {
      const payload = {
        milestoneId: Number(idNumber),
      }
      const response = await this.$axios.post('/milestones', payload)
      if (response.status === 201) {
        commit('setAchievedMilestones', idNumber)
      }
    } catch (e) {
      commit('setError', 'Unable to post milestones')
    } finally {
      commit('setLoading', false)
    }
  },
}

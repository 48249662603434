import { datadogRum } from '@datadog/browser-rum'

datadogRum.init({
  applicationId: process.env.DATADOG_RUM_APP_ID,
  clientToken: process.env.DATADOG_RUM_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: process.env.DATADOG_RUM_SERVICE_NAME,
  env: process.env.DATADOG_ENV_NAME,

  // Specify a version number to identify the deployed version of your application in Datadog
  version: '1.0.0',
  sampleRate: 100,
  premiumSampleRate: 100,
  trackInteractions: true,
})


import { mapState } from 'vuex'
import Cookies from 'js-cookie'
import ChevronUp from '@/assets/icons/board/ChevronUp'

export default {
  components: {
    ChevronUp,
  },
  data () {
    return {
      showDropdown: false,
    }
  },
  computed: {
    ...mapState('board', ['boards', 'currBoard']),
    hoverStyle () {
      switch (this.currBoard.name) {
        case 'SPL':
          if (this.currBoard.year === 2023) {
            return ['hover:bg-[#FFF3EE]', 'hover:text-[#FD5A1E]']
          } else {
            return ['hover:bg-[#69B999]', 'hover:text-[#0B0C0F]']
          }
        case 'SPG':
          if (this.currBoard.year === 2023) {
            return ['hover:bg-[#E7EDFE]', 'hover:text-[#0357D4]']
          } else {
            return ['hover:bg-[#ECE2D2]', 'hover:text-[#795134]']
          }
        case 'SPC':
          if (this.currBoard.year === 2023) {
            return ['hover:bg-[#ffe395]', 'hover:text-[#1D428A]']
          } else {
            return ['hover:bg-[#ffe395]', 'hover:text-[#1D428A]']
          }
        case 'SPH':
          if (this.currBoard.year === 2023) {
            return ['hover:bg-[#a2ffda]', 'hover:text-[#0A9860]']
          } else {
            return ['hover:bg-[#867365]', 'hover:text-[#795134]']
          }
        case 'SPLyn':
          return ['hover:bg-[#E8DBD5]', 'hover:text-[#75474A]']
        default:
          return ['']
      }
    },
  },
  methods: {
    toggleDropdown () {
      this.showDropdown = !this.showDropdown
    },
    closeDropdown () {
      this.showDropdown = false
    },
    async updateCurrBoard (selectedBoard) {
      Cookies.set('cookieBoard', JSON.stringify({ id: selectedBoard.id, year: selectedBoard.year, isYearSelected: false }))
      this.toggleDropdown()
      this.$store.dispatch('board/updateCurrBoard', selectedBoard)
      this.$store.dispatch('board/resetFilters')
      await this.$store.dispatch('board/fetchIsCurrBoardAdmin', selectedBoard.id)
    },
  },
}

export const state = () => ({
  isSelectingPhotos: false,
  photos: [],
  selectedPhotos: [],
  isShowQr: false,
  loading: false,
})

export const getters = {
  isPhotoSelected: (state) => (photo) => {
    return state.selectedPhotos.includes(photo)
  },
  isPhotosEmpty: (state) => {
    return state.photos.length === 0
  },
}

export const mutations = {
  setIsSelectingPhotos (state, value) {
    state.isSelectingPhotos = value
  },
  setIsShowQr (state, value) {
    state.isShowQr = value
  },
  setPhotos (state, photos) {
    state.photos = photos
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  addToSelectedPhotos (state, photo) {
    state.selectedPhotos.push(photo)
  },
  removeFromSelectedPhotos (state, photo) {
    state.selectedPhotos = state.selectedPhotos.filter((item) => item !== photo)
  },
  resetSelectedPhotos (state) {
    state.selectedPhotos = []
  },
}

export const actions = {
  updateIsSelectingPhotos ({ commit }, value) {
    commit('setIsSelectingPhotos', value)
  },
  updateIsShowQr ({ commit }, value) {
    commit('setIsShowQr', value)
  },
  async fetchPhotos ({ commit, dispatch }) {
    commit('setLoading', true)
    try {
      const { data: photos } = await this.$axios.get('/photo/all')
      commit('setPhotos', photos)
    } catch (e) {
      dispatch('toast/error', 'Failed to fetch photos.', { root: true })
    } finally {
      commit('setLoading', false)
    }
  },
  toggleSelectedPhoto ({ getters, commit }, photo) {
    if (getters.isPhotoSelected(photo)) commit('removeFromSelectedPhotos', photo)
    else commit('addToSelectedPhotos', photo)
  },
  resetSelectedPhotos ({ commit }) {
    commit('resetSelectedPhotos')
  },
}

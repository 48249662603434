import STATUSES from '@/constants/STATUSES'

export const state = () => {
  const currentYear = new Date().getFullYear()
  const years = [...Array(100).keys()].map(key => currentYear - key)
  // TODO: need to fix
  // years.unshift('Prefer not to say')

  return {
    status: STATUSES.PENDING,
    genders: [{ id: 'M', name: 'Male' }, { id: 'F', name: 'Female' }],
    nationalities: [],
    residentialAreas: [],
    maritalStatuses: [],
    educationalPhases: [],
    schools: {},
    course: {},
    industries: [],
    transitions: [],
    stagesOfLife: [],
    christianJourneys: [],
    pastoralMapping: {},
    cgMapping: {},
    years,
    zoneCgMapping: {},
  }
}

export const mutations = {
  set (state, { key, value }) {
    state[key] = value
  },
  setCgMapping (state, { value }) {
    state.cgMapping = value
  },
  setZoneCgMapping (state, { value }) {
    state.zoneCgMapping = value
  },
}

export const actions = {
  async fetchCgMapping ({ state, commit, dispatch }) {
    if (Object.keys(state.cgMapping).length) return

    try {
      const { data } = await this.$axios.get('/profile/cg-mapping')
      commit('setCgMapping', { value: data })
    } catch (e) {
      dispatch('toast/error', 'Failed to fetch cg list', { root: true })
    }
  },
  async fetchZoneCgMapping ({ state, commit, dispatch }) {
    if (Object.keys(state.cgMapping).length) return

    try {
      const { data } = await this.$axios.get('/profile/zone-cg-mapping')
      commit('setZoneCgMapping', { value: data })
    } catch (e) {
      dispatch('toast/error', 'Failed to fetch zone cg mapping', { root: true })
    }
  },
  async fetchIfNotYet ({ state, commit, dispatch }) {
    if (state.status !== STATUSES.PENDING) return

    commit('set', { key: 'status', value: STATUSES.LOADING })

    try {
      const [dropdowns, mapping] = await Promise.all([
        this.$axios.get('/profile/dropdowns'),
        this.$axios.get('/profile/pastoral-mapping'),
      ])

      const lookups = [
        'nationalities', 'residentialAreas', 'maritalStatuses',
        'educationalPhases', 'schools', 'course', 'industries',
        'transitions', 'stagesOfLife', 'christianJourneys',
      ]

      lookups.forEach(key => commit('set', { key, value: dropdowns.data[key] }))

      commit('set', { key: 'pastoralMapping', value: mapping.data })
      commit('set', { key: 'status', value: STATUSES.DONE })
    } catch (e) {
      dispatch('toast/error', 'Failed to connect to server. Please try again.', { root: true })
    }
  },
}


import { mapState } from 'vuex'
import CloseIcon from '@/assets/icons/board/CloseIcon'
import BackToBoard from '@/assets/icons/board/sidebar/BackToBoard'
import FavouriteMessages from '@/assets/icons/board/sidebar/FavouriteMessages'
import HOGCLogo from '@/assets/icons/board/sidebar/HOGCLogo'
import HiddenMessages from '@/assets/icons/board/sidebar/HiddenMessages'
import MyMessages from '@/assets/icons/board/sidebar/MyMessages'
import PinnedMessages from '@/assets/icons/board/sidebar/PinnedMessages'

export default {
  components: {
    CloseIcon,
    BackToBoard,
    FavouriteMessages,
    HOGCLogo,
    HiddenMessages,
    MyMessages,
    PinnedMessages,
  },
  props: {
    isMainPage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('board', ['currBoard', 'isCurrBoardAdmin', 'showSideBar']),
    menuItems () {
      return [
        {
          title: 'Back to Main Board',
          logo: 'back-to-board',
          slug: 'birthdayboard',
          isAdmin: false,
        },
        {
          title: 'My Messages',
          logo: 'my-messages',
          slug: 'birthdayboard/my-messages',
          isAdmin: false,
          det: 'my-messages',
        },
        {
          title: 'Favourite Messages',
          logo: 'favourite-messages',
          slug: 'birthdayboard/favourite-messages',
          isAdmin: false,
          det: 'favourite-messages',
        },
        {
          title: 'Pinned Messages',
          logo: 'pinned-messages',
          slug: 'birthdayboard/pinned-messages',
          isAdmin: true,
        },
        {
          title: 'Hidden Messages',
          logo: 'hidden-messages',
          slug: 'birthdayboard/hidden-messages',
          isAdmin: true,
        },
        { title: 'Back to HOGC One', logo: 'logo-hogc', slug: '', isAdmin: false },
      ].filter((menuItem) => {
        if (menuItem.isAdmin) {
          return this.isCurrBoardAdmin
        }
        if (this.isMainPage) {
          return menuItem.slug !== 'birthdayboard'
        }
        return true
      })
    },
  },
  methods: {
    clickItem (slug) {
      this.closeSideBar()
      this.$router.push({ path: `/${slug}` })
    },
    closeSideBar () {
      this.$store.dispatch('board/updateShowSideBar', false)
    },
  },
}


import { mapState } from 'vuex'
import { REGISTER_STAGE } from '../../store/form'

export default {
  computed: {
    ...mapState('form', ['register']),
    isLandingPage () {
      return this.register.stage === REGISTER_STAGE.LANDING_PAGE
    },
  },
}

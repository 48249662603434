export const state = () => ({
  username: '',
  mobileNumber: '',
  isNumberValid: false,
})

export const mutations = {
  getUsername (state, username) {
    state.username = username
  },
  getMobileNumber (state, mobileNumber) {
    state.mobileNumber = mobileNumber
  },
  setIsNumberValid (state, isNumberValid) {
    state.isNumberValid = isNumberValid
  },
}

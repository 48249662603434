
import { mapGetters, mapState } from 'vuex'
import Avatar from '../icons/Avatar.vue'

import OutlineButton from './OutlineButton.vue'
import OutlineHomeIcon from '@/components/icons/OutlineHome'
import OutlineHappeningsIcon from '@/components/icons/OutlineHappenings'
import OutlineResourcesIcon from '@/components/icons/OutlineResources'
import OutlineGivingsIcon from '@/components/icons/OutlineGivings'
import OutlinePasswordLockIcon from '@/components/icons/OutlinePasswordLock'
import OutlineEditProfileIcon from '@/components/icons/OutlineEditProfile'
import OutlineImpressionSlipIcon from '@/components/icons/OutlineImpressionSlip'

export default {
  components: {
    OutlineButton,
    Avatar,
  },
  data () {
    return {
      links: [
        { to: '/', label: 'Home', icon: OutlineHomeIcon, dataEventTracking: 'home-bottom' },
        { to: '/happenings', label: 'Happenings', icon: OutlineHappeningsIcon, dataEventTracking: 'happenings-bottom' },
        { to: '/resources', label: 'Resources', icon: OutlineResourcesIcon, dataEventTracking: 'resources-bottom' },
        { to: 'https://mygiving.heartofgodchurch.org', label: 'MyGiving', icon: OutlineGivingsIcon, minAge: 17, minPastoralStatus: 1, isExternal: true, dataEventTracking: 'mygiving-bottom' },
      ],
      personalLinks: [
        { to: '/change-password', label: 'Change password', icon: OutlinePasswordLockIcon, dataEventTracking: 'change-password' },
        { to: '/profile', label: 'Edit Profile', icon: OutlineEditProfileIcon, dataEventTracking: 'edit-profile' },
        { to: '/impression', label: 'Send Impression Slip', icon: OutlineImpressionSlipIcon, dataEventTracking: 'send-impression-slip' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      age: 'profile/age',
      fullname: 'profile/fullname',
    }),
    ...mapGetters('profile', ['pastoralRoleId']),
    ...mapState('lookups', ['pastoralMapping']),
    filteredLinks () {
      return this.links.filter(l => !l.minAge || this.age > l.minAge).filter(l => !l.minPastoralStatus || this.pastoralMapping[this.pastoralRoleId] >= l.minPastoralStatus)
    },
  },
}

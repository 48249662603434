import { FORMS_CATEGORY, HAPPENINGS_CATEGORY, HOME_CATEGORY, RESOURCES_CATEGORY, JUST_FOR_YOU_CATEGORY, CALENDAR_EVENTS_CATEGORY, CALENDAR_EVENT_FILTERS_CATEGORY, CALENDAR_LAST_UPDATED_DATE_CATEGORY, CALENDAR_DOWNLOAD_LINK_CATEGORY, E_INVITES_CATEGORY, CALENDAR_NOTIFICATIONS_CATEGORY } from './categories'
import { DOWNLOADS_ENTITY, FORMS_ENTITY, HIGHLIGHTS_ENTITY, IDENTITY_RESOURCES_ENTITY, PERSONAL_GROWTHS_ENTITY, UPCOMING_EVENTS_ENTITY, WEEKEND_HIGHLIGHTS_ENTITY, WEEKLY_EVENTS_ENTITY, JUST_FOR_YOUS_ENTITY, CALENDAR_EVENTS_ENTITY, CALENDAR_EVENT_FILTERS_ENTITY, CALENDAR_LAST_UPDATED_DATE_ENTITY, CALENDAR_DOWNLOAD_LINK_ENTITY, E_INVITES_ENTITY, CALENDAR_NOTIFICATIONS_ENTITY } from './entities'

export const entityToCategoryMap = {
  [WEEKEND_HIGHLIGHTS_ENTITY]: HOME_CATEGORY,
  [UPCOMING_EVENTS_ENTITY]: HAPPENINGS_CATEGORY,
  [WEEKLY_EVENTS_ENTITY]: HAPPENINGS_CATEGORY,
  [DOWNLOADS_ENTITY]: RESOURCES_CATEGORY,
  [PERSONAL_GROWTHS_ENTITY]: RESOURCES_CATEGORY,
  [HIGHLIGHTS_ENTITY]: RESOURCES_CATEGORY,
  [IDENTITY_RESOURCES_ENTITY]: RESOURCES_CATEGORY,
  [FORMS_ENTITY]: FORMS_CATEGORY,
  [JUST_FOR_YOUS_ENTITY]: JUST_FOR_YOU_CATEGORY,
  [CALENDAR_EVENTS_ENTITY]: CALENDAR_EVENTS_CATEGORY,
  [CALENDAR_EVENT_FILTERS_ENTITY]: CALENDAR_EVENT_FILTERS_CATEGORY,
  [CALENDAR_LAST_UPDATED_DATE_ENTITY]: CALENDAR_LAST_UPDATED_DATE_CATEGORY,
  [CALENDAR_DOWNLOAD_LINK_ENTITY]: CALENDAR_DOWNLOAD_LINK_CATEGORY,
  [E_INVITES_ENTITY]: E_INVITES_CATEGORY,
  [CALENDAR_NOTIFICATIONS_ENTITY]: CALENDAR_NOTIFICATIONS_CATEGORY,
}

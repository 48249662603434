// Every time the route changes (fired on initialization too)
export default function ({ $axios, app }) {
  $axios.onError((error) => {
    // if from auth0 error & invalid grant then, return to home page
    if (
      error?.response?.config?.url.includes('auth0') &&
      error.response.data.error === 'invalid_grant'
    ) {
      console.error('Error:', error.response.data.error)
      window.location.href = '/'
    }
  })
}

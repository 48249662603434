// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/board/spc-navbar-mobile.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/board/spc-navbar-tablet.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/images/board/spc-navbar-desktop.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".background-style[data-v-11c92776]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover}@media (min-width:768px){.background-style[data-v-11c92776]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:cover}}@media (min-width:1024px){.background-style[data-v-11c92776]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-size:cover}}.black-hamburger[data-v-11c92776]{filter:invert(1)}.brown-hamburger[data-v-11c92776]{filter:brightness(0) saturate(100%) invert(43%) sepia(10%) saturate(1000%) hue-rotate(351deg) brightness(96%) contrast(95%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;


import { mapState } from 'vuex'
import { REGISTER_STAGE } from '../store/form'

import RegisterHeader from '../components/RegisterPage/RegisterHeader.vue'
import OTPVerifiedModal from '../components/RegisterPage/OTPVerifiedModal.vue'
import RegisterPageDesktopEllipse from '../components/RegisterPage/RegisterPageDesktopEllipse.vue'
import RegisterPageMobileEllipse from '../components/RegisterPage/RegisterPageMobileEllipse.vue'
import RegisterPageMobileLogo from '../components/RegisterPage/RegisterPageMobileLogo.vue'
import RegisterPageDesktopLogo from '../components/RegisterPage/RegisterPageDesktopLogo.vue'
import Toast from '../components/common/Toast.vue'
export default {
  components: { RegisterHeader, OTPVerifiedModal, RegisterPageDesktopEllipse, RegisterPageMobileEllipse, RegisterPageMobileLogo, RegisterPageDesktopLogo, Toast },
  computed: {
    ...mapState('form', ['register']),
    isLandingPage () {
      return this.register.stage === REGISTER_STAGE.LANDING_PAGE
    },
  },
}

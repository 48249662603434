
import { mapState } from 'vuex'
import Cookies from 'js-cookie'
import Loading from '@/components/common/Loading'
import BoardHeader from '@/components/Board/Main/BoardHeader'
import SideBar from '@/components/Board/Main/SideBar'
import FilterBar from '@/components/Board/Filter/FilterBar'

export default {
  components: {
    Loading,
    BoardHeader,
    SideBar,
    FilterBar,
  },
  computed: {
    ...mapState('board', ['isLoading', 'currBoard', 'showSideBar', 'isYearSelected']),

    opaqueClass () {
      return this.showSideBar ? 'brightness-50 bg-[#F0F2F4] h-[100vh]' : ''
    },
    sideBarActive () {
      return this.showSideBar ? 'no-scroll' : ''
    },
    showFilterBar () {
      // Don't show when we are writing message
      if (this.$route.path === '/birthdayboard/message') return false
      // if path is main page, we return base on whether is Year selected or not
      if (this.$route.path === '/birthdayboard') return this.isYearSelected
      // else always return true
      return true
    },
  },
  async mounted () {
    let cookieBoard = ''
    if (Cookies.get('cookieBoard') === undefined) {
      cookieBoard = null
    } else {
      cookieBoard = JSON.parse(Cookies.get('cookieBoard'))
    }
    const query = this.$route
    const regex = /\?(.+)/
    const match = query.fullPath.match(regex)
    if (match) {
      const queryParameter = match[1]
      if (queryParameter === 'spl') {
        await this.fetchBoard(1)
      }
      if (queryParameter === 'spg') {
        await this.fetchBoard(2)
      }
      if (queryParameter === 'sph') {
        await this.fetchBoard(3)
      }
      if (queryParameter === 'spc') {
        await this.fetchBoard(4)
      }
      if (queryParameter === 'splyn') {
        await this.fetchBoard(5)
      }
    } else {
      await this.$store.dispatch('board/fetchBoards', null, cookieBoard)
      await this.$store.dispatch(
        'board/fetchIsCurrBoardAdmin',
        this.currBoard ? this.currBoard.id : 0,
      )
    }
  },
  async created () {
    await Promise.all([this.$store.dispatch('lookups/fetchZoneCgMapping')])
  },
  methods: {
    async fetchBoard (queryBoardId) {
      await this.$store.dispatch('board/fetchBoards', queryBoardId)
      await this.$store.dispatch('board/fetchIsCurrBoardAdmin', queryBoardId)
      this.$router.replace({ fullPath: this.$route.path })
    },
  },
}

import { APP_TIMEZONE } from '../constants/timezone'

export function dateToTime (date) {
  const hours = date.getHours()
  const ampm = hours > 12 ? 'pm' : 'am'
  const mins = (date.getMinutes()).toString().padStart(2, '0')
  return `${hours % 12 || 12}:${mins}${ampm}`
}

export function formatDate (date, setTimezone = true) {
  const year = date.getFullYear()
  const day = date.getDate()
  const monthStringOptions = { month: 'short' }
  const weekdayStringOptions = { weekday: 'long' }

  if (setTimezone) {
    monthStringOptions.timeZone = APP_TIMEZONE
    weekdayStringOptions.timeZone = APP_TIMEZONE
  }

  const month = date.toLocaleString('en-us', monthStringOptions)
  const weekday = date.toLocaleString('en-us', weekdayStringOptions)

  return `${weekday}, ${day} ${month} ${year}`
}

export function formatTime (date) {
  const formatter = new Intl.DateTimeFormat('en-us', { hour: 'numeric', hour12: true, minute: 'numeric', timeZone: APP_TIMEZONE })
  return formatter.format(date)
}


import { mapGetters } from 'vuex'
import OutlineButton from '../common/OutlineButton.vue'
import Avatar from '../icons/Avatar.vue'
import CancelIcon from '@/components/icons/Cancel'
import OutlinePasswordLockIcon from '@/components/icons/OutlinePasswordLock'
import OutlineEditProfileIcon from '@/components/icons/OutlineEditProfile'
import OutlineImpressionSlipIcon from '@/components/icons/OutlineImpressionSlip'

export default {
  components: {
    CancelIcon,
    OutlineButton,
    Avatar,
  },
  data () {
    return {
      personalLinks: [
        { to: '/change-password', label: 'Change password', icon: OutlinePasswordLockIcon },
        { to: '/profile', label: 'Edit Profile', icon: OutlineEditProfileIcon },
        { to: '/impression', label: 'Send Impression Slip', icon: OutlineImpressionSlipIcon },
      ],
    }
  },
  computed: {
    ...mapGetters({
      fullname: 'profile/fullname',
    }),
  },
}

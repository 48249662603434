
import markerSDK from '@marker.io/browser'
import Toast from '../components/common/Toast.vue'
import PhotosHeader from '../components/Photo/PhotosHeader'
import Sidebar from '@/components/common/Sidebar'
import BottomNav from '@/components/common/BottomNav'
import Global from '@/mixins/Global'

export default {
  components: {
    Sidebar,
    BottomNav,
    Toast,
    PhotosHeader,
  },
  mixins: [Global],
  data () {
    return {
      showingProfileModal: false,
    }
  },
  async created () {
    this.$store.dispatch('profile/fetchProfile')
    if (process.env.NODE_ENV === 'staging') {
      const widget = await markerSDK.loadWidget({
        destination: process.env.MARKERIO_DESTINATION, // <- Your unique destination ID
      })
      widget.setReporter({ email: this.email, fullName: this.name })
    }
  },
}

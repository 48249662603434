import { dateToTime } from '../util/formatDateToTime'

export const state = () => ({
  allocation: null,
  openService: null,
  loading: false,
  error: null,
})

export const getters = {
  getServiceId (state) {
    if (state.openService) return state.openService.service_id
  },
  getServiceTiming (state) {
    if (state.openService) {
      const locale = 'en-us'
      const startTime = new Date(state.openService.service_start_time)
      const endTime = new Date(state.openService.service_end_time)
      return `${startTime.toLocaleDateString(locale, { weekday: 'short' })} ${dateToTime(startTime)} - ${dateToTime(endTime)}`
    }
  },
}

export const mutations = {
  setAllocation (state, newAllocation) {
    state.allocation = newAllocation
  },
  setOpenService (state, openService) {
    state.openService = openService
  },
  setLoading (state, isLoading) {
    state.loading = isLoading
  },
  setError (state, error) {
    state.error = error
  },
}

export const actions = {
  async fetchOpenService ({ commit }) {
    commit('setLoading', true)
    commit('setError', null)
    try {
      const newAxios = await this.$axios.create({})
      delete newAxios.defaults.headers.common.Authorization
      newAxios.defaults.headers['Content-Type'] = 'application/json'
      const service = await newAxios.get(`${process.env.SOWSEATS_API_URL}/api/check-in`)
      commit('setOpenService', service.data.data)
    } catch (e) {

    } finally {
      commit('setLoading', false)
    }
  },

  async fetchAllocation ({ commit }, { serviceId, groupId }) {
    commit('setLoading', true)
    commit('setError', null)
    try {
      const newAxios = await this.$axios.create({})
      delete newAxios.defaults.headers.common.Authorization
      newAxios.defaults.headers['Content-Type'] = 'application/json'
      const allocation = await newAxios.post(`${process.env.SOWSEATS_API_URL}/api/check-in/allocation`, {
        serviceId,
        groupId,
      })
      commit('setAllocation', allocation.data.data)
    } catch (e) {
      commit('setError', 'Group ID is invalid. Please approach an usher.')
    } finally {
      commit('setLoading', false)
    }
  },
}


import { mapState } from 'vuex'
import CloseIcon from '@/assets/icons/board/CloseIcon.vue'

export default {
  components: {
    CloseIcon,
  },
  computed: {
    ...mapState('board', ['boardToastMessage']),
  },
  methods: {
    closeToast () {
      this.$store.dispatch('board/updateIsBoardToastOpen', false)
    },
  },
}


import Toast from '../components/common/Toast.vue'
export default {
  components: { Toast },
  data () {
    return {
      isSuccessful: false,
    }
  },
  created () {
    this.$nuxt.$on('success', () => {
      this.isSuccessful = true
    })
  },
}


import { mapGetters, mapState } from 'vuex'
import markerSDK from '@marker.io/browser'
import Header from '../components/common/Header.vue'
import Toast from '../components/common/Toast.vue'
import Sidebar from '@/components/common/Sidebar'
import BottomNav from '@/components/common/BottomNav'
import ProfileModal from '@/components/modals/Profile'
import Global from '@/mixins/Global'

export default {
  components: {
    Sidebar,
    BottomNav,
    Header,
    ProfileModal,
    Toast,
  },
  mixins: [Global],
  data () {
    return {
      windowWidth: null,
      showingProfileModal: false,
    }
  },
  computed: {
    ...mapState('lookups', ['pastoralMapping']),
    ...mapGetters('profile', ['pastoralRoleId', 'isLeader']),
  },
  watch: {
    windowWidth (width) {
      if (width >= 1024) {
        this.showingProfileModal = false
      }
    },
    showingProfileModal (isShowing) {
      if (isShowing) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    },
    pastoralMapping (pastoralMapping) {
      if (this.pastoralRoleId || this.isLeader) {
        const isLdr = pastoralMapping[this.pastoralRoleId] >= 6 || this.isLeader
        this.$store.dispatch('profile/updateIsLeader', isLdr)
        this.$store.dispatch('calendar/updateIsLeadersCalendar', isLdr)
      }
    },
    pastoralRoleId (pastoralRoleId) {
      if (this.pastoralMapping || this.isLeader) {
        const isLdr = this.pastoralMapping[pastoralRoleId] >= 6 || this.isLeader
        this.$store.dispatch('profile/updateIsLeader', isLdr)
        this.$store.dispatch('calendar/updateIsLeadersCalendar', isLdr)
      }
    },
  },
  async created () {
    await Promise.all([
      this.$store.dispatch('profile/fetchProfile'),
      this.$store.dispatch('profile/fetchPastoralAbbrevMapping'),
      this.$store.dispatch('profile/fetchMinistryStatus'),
      this.$store.dispatch('lookups/fetchIfNotYet'),
      this.$store.dispatch('lookups/fetchCgMapping'),
      this.$store.dispatch('lookups/fetchZoneCgMapping'),
    ])
    if (process.env.NODE_ENV === 'staging') {
      const widget = await markerSDK.loadWidget({
        destination: process.env.MARKERIO_DESTINATION, // <- Your unique destination ID
      })
      widget.setReporter({ email: this.email, fullName: this.name })
    }
  },
}

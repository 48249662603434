import STATUSES from '@/constants/STATUSES'

export const getters = {
  isLoading (state, getters, rootState) {
    return rootState.profile.loading || rootState.lookups.status === STATUSES.LOADING
  },
}

export const actions = {
  setLoading ({ commit }, isLoading) {
    commit('profile/setLoading', isLoading)
  },
}

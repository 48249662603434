export const HOME_CATEGORY = 'HOME'
export const HAPPENINGS_CATEGORY = 'HAPPENINGS'
export const RESOURCES_CATEGORY = 'RESOURCES'
export const FORMS_CATEGORY = 'FORMS'
export const JUST_FOR_YOU_CATEGORY = 'JUST_FOR_YOUS'
export const CALENDAR_EVENTS_CATEGORY = 'CALENDAR_EVENTS'
export const CALENDAR_EVENT_FILTERS_CATEGORY = 'CALENDAR_EVENT_FILTERS'
export const CALENDAR_LAST_UPDATED_DATE_CATEGORY = 'CALENDAR_LAST_UPDATED_DATE'
export const CALENDAR_DOWNLOAD_LINK_CATEGORY = 'CALENDAR_DOWNLOAD_LINK'
export const E_INVITES_CATEGORY = 'E_INVITES'
export const CALENDAR_NOTIFICATIONS_CATEGORY = 'CALENDAR_NOTIFICATIONS'
